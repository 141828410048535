import { Box, Typography } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import { FC } from 'react';
import { Carousel } from '@/components/ui';
import { useMobileQuery } from '@/utils/hooks';
import { Review } from '@/components/ui/types';

interface ClientReviewsProps {
  reviews: Review[];
}

const ClientReviews: FC<ClientReviewsProps> = ({ reviews }) => {
  const isMobile = useMobileQuery();

  const reviewCards = reviews.map(({ author, comment, location }, index) => (
    <Box
      key={index}
      sx={{
        fontSize: '18px',
        lineHeight: '24px',
        margin: '0 auto',
        textAlign: 'center',
        width: '296px',
        [theme.breakpoints.up('md')]: {
          color: theme.palette.text.primary,
          marginTop: '24px',
          textAlign: 'left',
          width: 'auto',
        },
      }}>
      <Typography>{comment}</Typography>
      <Typography
        color={theme.palette.care?.grey[500]}
        variant="body3"
        sx={{
          fontSize: '12px',
          marginLeft: '5px',
        }}>
        {author}
        {location !== '' && ','} {location}
      </Typography>
    </Box>
  ));

  if (isMobile) {
    return (
      <Box
        sx={{
          [theme.breakpoints.down('md')]: {
            height: '225px',
            margin: '0 auto',
          },
        }}
        data-testid="reviews-wrapper">
        <Carousel>{reviewCards}</Carousel>
      </Box>
    );
  }

  return <Box data-testid="reviews-wrapper">{reviewCards}</Box>;
};

export default ClientReviews;
